<template>
  <div class="page_search">
	<div class="warp">
	  <div class="container">
		<div class="row">
		  <div class="col-12">
			<div class="card_result_search">
			  <div class="title">搜索结果</div>

				<!-- 文章搜索结果 -->
			  <!-- <list_result_search
				:list="result_article"
				title="新闻资讯"
				source_table="article"
			  ></list_result_search> -->


									  <list_result_search
				v-if="$check_action('/application_user/list', 'get')"
				:list="result_application_user_user_name"
				title="申请用户用户姓名"
				source_table="application_user"
			  ></list_result_search>
															  <list_result_search
				v-if="$check_action('/material_manager/list', 'get')"
				:list="result_material_manager_manager_name"
				title="物资管理者管理者姓名"
				source_table="material_manager"
			  ></list_result_search>
															  <list_result_search
				v-if="$check_action('/secondary_approver/list', 'get')"
				:list="result_secondary_approver_name_of_approver"
				title="二级审批人审批人姓名"
				source_table="secondary_approver"
			  ></list_result_search>
															  <list_result_search
				:list="result_material_information_item_name"
				title="物资信息物资名称"
				source_table="material_information"
			  ></list_result_search>
											  <list_result_search
				:list="result_material_information_category_shown"
				title="物资信息所示类别"
				source_table="material_information"
			  ></list_result_search>
																		  <list_result_search
				v-if="$check_action('/material_classification/list', 'get')"
				:list="result_material_classification_classification_name"
				title="物资分类分类名称"
				source_table="material_classification"
			  ></list_result_search>
												  <list_result_search
				v-if="$check_action('/material_application/list', 'get')"
				:list="result_material_application_item_name"
				title="物资申领物资名称"
				source_table="material_application"
			  ></list_result_search>
											  <list_result_search
				v-if="$check_action('/material_application/list', 'get')"
				:list="result_material_application_category_shown"
				title="物资申领所示类别"
				source_table="material_application"
			  ></list_result_search>
											  <list_result_search
				v-if="$check_action('/material_application/list', 'get')"
				:list="result_material_application_user_name"
				title="物资申领用户姓名"
				source_table="material_application"
			  ></list_result_search>
																								  <list_result_search
				v-if="$check_action('/material_replenishment/list', 'get')"
				:list="result_material_replenishment_item_name"
				title="物资补货物资名称"
				source_table="material_replenishment"
			  ></list_result_search>
											  <list_result_search
				v-if="$check_action('/material_replenishment/list', 'get')"
				:list="result_material_replenishment_category_shown"
				title="物资补货所示类别"
				source_table="material_replenishment"
			  ></list_result_search>
											  <list_result_search
				v-if="$check_action('/material_replenishment/list', 'get')"
				:list="result_material_replenishment_manager_name"
				title="物资补货管理者姓名"
				source_table="material_replenishment"
			  ></list_result_search>
																					  <list_result_search
				v-if="$check_action('/excessive_application/list', 'get')"
				:list="result_excessive_application_item_name"
				title="超额申领物资名称"
				source_table="excessive_application"
			  ></list_result_search>
											  <list_result_search
				v-if="$check_action('/excessive_application/list', 'get')"
				:list="result_excessive_application_category_shown"
				title="超额申领所示类别"
				source_table="excessive_application"
			  ></list_result_search>
											  <list_result_search
				v-if="$check_action('/excessive_application/list', 'get')"
				:list="result_excessive_application_user_name"
				title="超额申领用户姓名"
				source_table="excessive_application"
			  ></list_result_search>
																								  <list_result_search
				v-if="$check_action('/material_quota/list', 'get')"
				:list="result_material_quota_item_name"
				title="物资配额物资名称"
				source_table="material_quota"
			  ></list_result_search>
											  <list_result_search
				v-if="$check_action('/material_quota/list', 'get')"
				:list="result_material_quota_category_shown"
				title="物资配额所示类别"
				source_table="material_quota"
			  ></list_result_search>
																											  <list_result_search
				v-if="$check_action('/department_application_report/list', 'get')"
				:list="result_department_application_report_title"
				title="部门申领报表标题"
				source_table="department_application_report"
			  ></list_result_search>
								  <list_result_search
				v-if="$check_action('/department_application_report/list', 'get')"
				:list="result_department_application_report_department_name"
				title="部门申领报表部门名称"
				source_table="department_application_report"
			  ></list_result_search>
																								  <list_result_search
				v-if="$check_action('/material_requisition_report/list', 'get')"
				:list="result_material_requisition_report_title"
				title="物资申领报表标题"
				source_table="material_requisition_report"
			  ></list_result_search>
								  <list_result_search
				v-if="$check_action('/material_requisition_report/list', 'get')"
				:list="result_material_requisition_report_item_name"
				title="物资申领报表物资名称"
				source_table="material_requisition_report"
			  ></list_result_search>
																								  <list_result_search
				v-if="$check_action('/excess_claim_statement/list', 'get')"
				:list="result_excess_claim_statement_title"
				title="超额申领报表标题"
				source_table="excess_claim_statement"
			  ></list_result_search>
								  <list_result_search
				v-if="$check_action('/excess_claim_statement/list', 'get')"
				:list="result_excess_claim_statement_item_name"
				title="超额申领报表物资名称"
				source_table="excess_claim_statement"
			  ></list_result_search>
																					</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>
</template>

<script>
import mixin from "../../mixins/page.js";
import list_result_search from "../../components/diy/list_result_search.vue";

export default {
  mixins: [mixin],
  data() {
	return {
	  "query": {
		word: "",
	  },
	  "result_article": [],
									"result_application_user_user_name":[],
															"result_material_manager_manager_name":[],
															"result_secondary_approver_name_of_approver":[],
															"result_material_information_item_name":[],
											"result_material_information_category_shown":[],
																		"result_material_classification_classification_name":[],
												"result_material_application_item_name":[],
											"result_material_application_category_shown":[],
											"result_material_application_user_name":[],
																								"result_material_replenishment_item_name":[],
											"result_material_replenishment_category_shown":[],
											"result_material_replenishment_manager_name":[],
																					"result_excessive_application_item_name":[],
											"result_excessive_application_category_shown":[],
											"result_excessive_application_user_name":[],
																								"result_material_quota_item_name":[],
											"result_material_quota_category_shown":[],
																											"result_department_application_report_title":[],
								"result_department_application_report_department_name":[],
																								"result_material_requisition_report_title":[],
								"result_material_requisition_report_item_name":[],
																								"result_excess_claim_statement_title":[],
								"result_excess_claim_statement_item_name":[],
																			};
  },
  methods: {
	/**
	 * 获取文章
	 */
	get_article() {
	  this.$get("~/api/article/get_list?like=0", { page: 1, size: 10, title: this.query.word }, (json) => {
		if (json.result) {
		  this.result_article = json.result.list;
		}
	  });
	},

							/**
	 * 获取user_name
	 */
	get_application_user_user_name(){
		this.$get("~/api/application_user/get_list?like=0", { page: 1, size: 10, "user_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_application_user_user_name = json.result.list;
			result_application_user_user_name.map(o => o.title = o['user_name'])
	  			this.result_application_user_user_name = result_application_user_user_name
		 	}
		});
	},
													/**
	 * 获取manager_name
	 */
	get_material_manager_manager_name(){
		this.$get("~/api/material_manager/get_list?like=0", { page: 1, size: 10, "manager_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_manager_manager_name = json.result.list;
			result_material_manager_manager_name.map(o => o.title = o['manager_name'])
	  			this.result_material_manager_manager_name = result_material_manager_manager_name
		 	}
		});
	},
													/**
	 * 获取name_of_approver
	 */
	get_secondary_approver_name_of_approver(){
		this.$get("~/api/secondary_approver/get_list?like=0", { page: 1, size: 10, "name_of_approver": this.query.word }, (json) => {
		  if (json.result) {
			var result_secondary_approver_name_of_approver = json.result.list;
			result_secondary_approver_name_of_approver.map(o => o.title = o['name_of_approver'])
	  			this.result_secondary_approver_name_of_approver = result_secondary_approver_name_of_approver
		 	}
		});
	},
													/**
	 * 获取item_name
	 */
	get_material_information_item_name(){
		this.$get("~/api/material_information/get_list?like=0", { page: 1, size: 10, "item_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_information_item_name = json.result.list;
			result_material_information_item_name.map(o => o.title = o['item_name'])
	  			this.result_material_information_item_name = result_material_information_item_name
		 	}
		});
	},
									/**
	 * 获取category_shown
	 */
	get_material_information_category_shown(){
		this.$get("~/api/material_information/get_list?like=0", { page: 1, size: 10, "category_shown": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_information_category_shown = json.result.list;
			result_material_information_category_shown.map(o => o.title = o['category_shown'])
	  			this.result_material_information_category_shown = result_material_information_category_shown
		 	}
		});
	},
																/**
	 * 获取classification_name
	 */
	get_material_classification_classification_name(){
		this.$get("~/api/material_classification/get_list?like=0", { page: 1, size: 10, "classification_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_classification_classification_name = json.result.list;
			result_material_classification_classification_name.map(o => o.title = o['classification_name'])
	  			this.result_material_classification_classification_name = result_material_classification_classification_name
		 	}
		});
	},
										/**
	 * 获取item_name
	 */
	get_material_application_item_name(){
		this.$get("~/api/material_application/get_list?like=0", { page: 1, size: 10, "item_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_application_item_name = json.result.list;
			result_material_application_item_name.map(o => o.title = o['item_name'])
	  			this.result_material_application_item_name = result_material_application_item_name
		 	}
		});
	},
									/**
	 * 获取category_shown
	 */
	get_material_application_category_shown(){
		this.$get("~/api/material_application/get_list?like=0", { page: 1, size: 10, "category_shown": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_application_category_shown = json.result.list;
			result_material_application_category_shown.map(o => o.title = o['category_shown'])
	  			this.result_material_application_category_shown = result_material_application_category_shown
		 	}
		});
	},
									/**
	 * 获取user_name
	 */
	get_material_application_user_name(){
		this.$get("~/api/material_application/get_list?like=0", { page: 1, size: 10, "user_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_application_user_name = json.result.list;
			result_material_application_user_name.map(o => o.title = o['user_name'])
	  			this.result_material_application_user_name = result_material_application_user_name
		 	}
		});
	},
																						/**
	 * 获取item_name
	 */
	get_material_replenishment_item_name(){
		this.$get("~/api/material_replenishment/get_list?like=0", { page: 1, size: 10, "item_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_replenishment_item_name = json.result.list;
			result_material_replenishment_item_name.map(o => o.title = o['item_name'])
	  			this.result_material_replenishment_item_name = result_material_replenishment_item_name
		 	}
		});
	},
									/**
	 * 获取category_shown
	 */
	get_material_replenishment_category_shown(){
		this.$get("~/api/material_replenishment/get_list?like=0", { page: 1, size: 10, "category_shown": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_replenishment_category_shown = json.result.list;
			result_material_replenishment_category_shown.map(o => o.title = o['category_shown'])
	  			this.result_material_replenishment_category_shown = result_material_replenishment_category_shown
		 	}
		});
	},
									/**
	 * 获取manager_name
	 */
	get_material_replenishment_manager_name(){
		this.$get("~/api/material_replenishment/get_list?like=0", { page: 1, size: 10, "manager_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_replenishment_manager_name = json.result.list;
			result_material_replenishment_manager_name.map(o => o.title = o['manager_name'])
	  			this.result_material_replenishment_manager_name = result_material_replenishment_manager_name
		 	}
		});
	},
																			/**
	 * 获取item_name
	 */
	get_excessive_application_item_name(){
		this.$get("~/api/excessive_application/get_list?like=0", { page: 1, size: 10, "item_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_excessive_application_item_name = json.result.list;
			result_excessive_application_item_name.map(o => o.title = o['item_name'])
	  			this.result_excessive_application_item_name = result_excessive_application_item_name
		 	}
		});
	},
									/**
	 * 获取category_shown
	 */
	get_excessive_application_category_shown(){
		this.$get("~/api/excessive_application/get_list?like=0", { page: 1, size: 10, "category_shown": this.query.word }, (json) => {
		  if (json.result) {
			var result_excessive_application_category_shown = json.result.list;
			result_excessive_application_category_shown.map(o => o.title = o['category_shown'])
	  			this.result_excessive_application_category_shown = result_excessive_application_category_shown
		 	}
		});
	},
									/**
	 * 获取user_name
	 */
	get_excessive_application_user_name(){
		this.$get("~/api/excessive_application/get_list?like=0", { page: 1, size: 10, "user_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_excessive_application_user_name = json.result.list;
			result_excessive_application_user_name.map(o => o.title = o['user_name'])
	  			this.result_excessive_application_user_name = result_excessive_application_user_name
		 	}
		});
	},
																						/**
	 * 获取item_name
	 */
	get_material_quota_item_name(){
		this.$get("~/api/material_quota/get_list?like=0", { page: 1, size: 10, "item_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_quota_item_name = json.result.list;
			result_material_quota_item_name.map(o => o.title = o['item_name'])
	  			this.result_material_quota_item_name = result_material_quota_item_name
		 	}
		});
	},
									/**
	 * 获取category_shown
	 */
	get_material_quota_category_shown(){
		this.$get("~/api/material_quota/get_list?like=0", { page: 1, size: 10, "category_shown": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_quota_category_shown = json.result.list;
			result_material_quota_category_shown.map(o => o.title = o['category_shown'])
	  			this.result_material_quota_category_shown = result_material_quota_category_shown
		 	}
		});
	},
																									/**
	 * 获取title
	 */
	get_department_application_report_title(){
		this.$get("~/api/department_application_report/get_list?like=0", { page: 1, size: 10, "title": this.query.word }, (json) => {
		  if (json.result) {
			var result_department_application_report_title = json.result.list;
			result_department_application_report_title.map(o => o.title = o['title'])
	  			this.result_department_application_report_title = result_department_application_report_title
		 	}
		});
	},
						/**
	 * 获取department_name
	 */
	get_department_application_report_department_name(){
		this.$get("~/api/department_application_report/get_list?like=0", { page: 1, size: 10, "department_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_department_application_report_department_name = json.result.list;
			result_department_application_report_department_name.map(o => o.title = o['department_name'])
	  			this.result_department_application_report_department_name = result_department_application_report_department_name
		 	}
		});
	},
																						/**
	 * 获取title
	 */
	get_material_requisition_report_title(){
		this.$get("~/api/material_requisition_report/get_list?like=0", { page: 1, size: 10, "title": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_requisition_report_title = json.result.list;
			result_material_requisition_report_title.map(o => o.title = o['title'])
	  			this.result_material_requisition_report_title = result_material_requisition_report_title
		 	}
		});
	},
						/**
	 * 获取item_name
	 */
	get_material_requisition_report_item_name(){
		this.$get("~/api/material_requisition_report/get_list?like=0", { page: 1, size: 10, "item_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_material_requisition_report_item_name = json.result.list;
			result_material_requisition_report_item_name.map(o => o.title = o['item_name'])
	  			this.result_material_requisition_report_item_name = result_material_requisition_report_item_name
		 	}
		});
	},
																						/**
	 * 获取title
	 */
	get_excess_claim_statement_title(){
		this.$get("~/api/excess_claim_statement/get_list?like=0", { page: 1, size: 10, "title": this.query.word }, (json) => {
		  if (json.result) {
			var result_excess_claim_statement_title = json.result.list;
			result_excess_claim_statement_title.map(o => o.title = o['title'])
	  			this.result_excess_claim_statement_title = result_excess_claim_statement_title
		 	}
		});
	},
						/**
	 * 获取item_name
	 */
	get_excess_claim_statement_item_name(){
		this.$get("~/api/excess_claim_statement/get_list?like=0", { page: 1, size: 10, "item_name": this.query.word }, (json) => {
		  if (json.result) {
			var result_excess_claim_statement_item_name = json.result.list;
			result_excess_claim_statement_item_name.map(o => o.title = o['item_name'])
	  			this.result_excess_claim_statement_item_name = result_excess_claim_statement_item_name
		 	}
		});
	},
																		
  },
  components: { list_result_search },
	created(){
    this.query.word = this.$route.query.word || "";
  },
  mounted() {
	this.get_article();
								this.get_application_user_user_name();
														this.get_material_manager_manager_name();
														this.get_secondary_approver_name_of_approver();
														this.get_material_information_item_name();
										this.get_material_information_category_shown();
																	this.get_material_classification_classification_name();
											this.get_material_application_item_name();
										this.get_material_application_category_shown();
										this.get_material_application_user_name();
																							this.get_material_replenishment_item_name();
										this.get_material_replenishment_category_shown();
										this.get_material_replenishment_manager_name();
																				this.get_excessive_application_item_name();
										this.get_excessive_application_category_shown();
										this.get_excessive_application_user_name();
																							this.get_material_quota_item_name();
										this.get_material_quota_category_shown();
																										this.get_department_application_report_title();
							this.get_department_application_report_department_name();
																							this.get_material_requisition_report_title();
							this.get_material_requisition_report_item_name();
																							this.get_excess_claim_statement_title();
							this.get_excess_claim_statement_item_name();
																		  },
  watch: {
	$route() {
	  $.push(this.query, this.$route.query);
	  this.get_article();
							  this.get_application_user_user_name();
													  this.get_material_manager_manager_name();
													  this.get_secondary_approver_name_of_approver();
													  this.get_material_information_item_name();
									  this.get_material_information_category_shown();
																  this.get_material_classification_classification_name();
										  this.get_material_application_item_name();
									  this.get_material_application_category_shown();
									  this.get_material_application_user_name();
																						  this.get_material_replenishment_item_name();
									  this.get_material_replenishment_category_shown();
									  this.get_material_replenishment_manager_name();
																			  this.get_excessive_application_item_name();
									  this.get_excessive_application_category_shown();
									  this.get_excessive_application_user_name();
																						  this.get_material_quota_item_name();
									  this.get_material_quota_category_shown();
																									  this.get_department_application_report_title();
						  this.get_department_application_report_department_name();
																						  this.get_material_requisition_report_title();
						  this.get_material_requisition_report_item_name();
																						  this.get_excess_claim_statement_title();
						  this.get_excess_claim_statement_item_name();
																			},
  },
};
</script>

<style scoped>
.card_search {
  text-align: center;
}
.card_result_search>.title {
  text-align: center;
  padding: 10px 0;
}
</style>
